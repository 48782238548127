import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { ICheckoutData } from 'services/checkout/checkout.types';
import { IPaymentMethod } from 'services/profile/profile.types';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Chip, CircularProgress, Dialog, Skeleton, Typography } from '@mui/material';

import Visa from 'assets/images/card-brands/visa.svg';
import MDRadio from 'material-ui/components/MDRadio';
import MDButton from 'material-ui/components/MDButton';
import Mastercard from 'assets/images/card-brands/mastercard.svg';
import CheckedImage from 'assets/images/checked.png';
import ConfettisImage from 'assets/images/confettis.png';
import useUnauthenticatedCheckoutService from 'services/checkout/UnauthenticatedService';

interface IPayments {
    data: ICheckoutData | any
}

const Payments: React.FC<IPayments> = ({ data }) => {

    const stripe = useStripe();
    const elements = useElements();

    const { getProfilePaymentsMethod, makeAnonymousSubscription, addAnonymousPayment } = useUnauthenticatedCheckoutService();

    const [ submiting, setSubmiting ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
    const [ canSubmitPayment, setCanSubmitPayment ] = useState<boolean>(false);
    const [ showSuccessModal, setShowSuccessModal ] = useState<boolean>(false);
    const [ isSubmitingPayment, setIsSubmitingPayment ] = useState<boolean>(false);
    const [ selectedPaymentMethodId, setSelectedPaymentMethodId ] = useState('');

    const { mutate: AddAnonymousPaymentMethod } = useMutation({
        mutationFn: addAnonymousPayment,
        onSuccess: (result) => {
            if (result.hasErrors) setErrorMessage(result.errors ? result.errors[0] : 'Something went wrong. Try again later.');
            else {
                handleSubscribe();
            }
            setTimeout(() => {
                setErrorMessage(null);
            }, 8000)
        },
    });

    const { mutate: makeSubscriptions } = useMutation({
        mutationFn: makeAnonymousSubscription,
        onSuccess: (response) => {
            if(response && response.hasErrors === false) {
                setSubmiting(false);
                setShowSuccessModal(true);
            } 
            
            if(response === undefined || (response && response.hasErrors)) {
                enqueueSnackbar((response && response.errors) ? response.errors[0] : 'Something went wrong. Try again later.', { variant: 'error' } );
                setSubmiting(false);
            }
        },
    });

    const { data: UserPaymentMethods, isLoading: loadingProfilePayments } = useQuery<any>({
        queryKey: ['paymentsOptionsData'],
        queryFn: () => 
            getProfilePaymentsMethod(data?.payload?.id.toString() ?? ''),
        refetchOnWindowFocus: false,
        enabled: !!data?.payload?.id,
    });

    const getCreditCardBrandToRender = (brand: string) => {
        switch (brand?.toLocaleLowerCase()) {
            case 'visa':
                return <Box component={'img'} src={Visa} width="60px" />
            case 'master':
                return <Box component={'img'} src={Mastercard} width="60px" />
            case 'mastercard':
                return <Box component={'img'} src={Mastercard} width="60px" />
            default:
                return <Box component={'img'} src={Visa} width="60px" />
        }
    }

    const handleSelectPaymentMethodId = (id: string) => { setSelectedPaymentMethodId(id) }

    const handleSubscribe = () => {
        setSubmiting(true);
        if (selectedPaymentMethodId === 'new') { 
            handlePaymentMethodBeforeSubscribe(); 
        } else {
            const objEnv = {
                ...data.payload,
                paymentMethodId: selectedPaymentMethodId
            }
    
            makeSubscriptions(objEnv);
        }
    }

    const handlePaymentMethodBeforeSubscribe = async () => {
        if (!stripe || !elements) { setErrorMessage('System error. Please try again later.'); return; }
    
        setIsSubmitingPayment(true);
        const card = elements.getElement(CardElement);
    
        if (!card) { setErrorMessage('Card element not found.'); setIsSubmitingPayment(false); return; }
    
        const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card: card });
    
        if (error) {
            setErrorMessage(error.message ?? 'An error occurred');
            setIsSubmitingPayment(false);
            setSubmiting(false);
        } else {
            setIsSubmitingPayment(true);
            AddAnonymousPaymentMethod({
                ...data.payload,
                paymentMethodId: paymentMethod.id
            });
            setSelectedPaymentMethodId(paymentMethod.id);
        }
    };

    useEffect(() => {
        if (UserPaymentMethods?.payload?.length > 0) {
            setSelectedPaymentMethodId(UserPaymentMethods.payload[0].id)
        }
    }, [UserPaymentMethods]);

    return (
        <React.Fragment>
            {
                !loadingProfilePayments && Array.isArray(UserPaymentMethods?.payload) && UserPaymentMethods?.payload.map((p: IPaymentMethod) => (
                    <Box sx={{ padding: '1rem', margin: '1rem 0', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px', display: 'flex', alignItems: 'center', height: '70px', gap: 2 , position: 'relative'}}>
                        <MDRadio 
                            value={p.id}
                            checked={selectedPaymentMethodId === p.id}
                            onChange={() => handleSelectPaymentMethodId(p.id)}
                        />
                        { getCreditCardBrandToRender(p.card.brand) }
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant='body2'>Credit Card ending in {p?.card?.last4}</Typography>
                            <Typography variant='caption' sx={{ padding: '.25rem 0', opacity: .8 }}>Valid Thru {p?.card?.exp_month && p?.card?.exp_month < 10 ? '0'+p?.card?.exp_month: p?.card?.exp_month}/{p?.card?.exp_year}</Typography>
                        </Box>
                    </Box>
                ))
            }

            {
                loadingProfilePayments && (
                    <Box>
                        {
                            [0,1,2].map(l => (
                                <Box key={l} sx={{ padding: '1rem', margin: '1rem 0', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px', display: 'flex', alignItems: 'center', height: '70px', gap: 2 , position: 'relative'}}>
                                    <Skeleton width={'20px'} height={'30px'} sx={{ borderRadius: '100%'}}/>
                                    <Skeleton width={'60px'} height={'60px'} />
                                    <Box>
                                        <Skeleton width={'212px'} height={'30px'} />
                                        <Skeleton width={'110px'} height={'30px'} />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                )
            }

            {
                !loadingProfilePayments && (
                    <Box sx={{ margin: '1rem 0', padding: '1rem', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '50px', gap: 2 , position: 'relative'}}>
                                <Box sx={{ display: 'flex' }}>
                                    <MDRadio 
                                        value="new"
                                        checked={selectedPaymentMethodId === 'new'}
                                        onChange={() => setSelectedPaymentMethodId('new')}
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <Typography variant='body2'>Add a new Credit Card</Typography>
                                    </Box>
                                </Box>

                        {
                            selectedPaymentMethodId === 'new' && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%', padding: '0 1rem' }}>
                                    <CardElement onChange={(e) => setCanSubmitPayment(e.complete)} />
                                    {
                                            errorMessage && (
                                                <Chip 
                                                    color='error' 
                                                    label={<Box sx={{ padding: '0 1rem', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>{errorMessage}</Box>} 
                                                    sx={{ 
                                                        height: 'auto', 
                                                        borderRadius: '8px', 
                                                        background: '#f8d7da',
                                                        color: '#721c24',
                                                        wordWrap: 'break-word', 
                                                        overflowWrap: 'break-word', 
                                                        whiteSpace: 'normal',  
                                                        display: 'block' 
                                                    }}
                                                />
                                            )
                                        }
                                </Box>
                            )
                        }
                    </Box>
                )
            }

            <MDButton fullWidth sx={{ height: '50px', fontSize: '16px'}} disabled={loadingProfilePayments || ((!canSubmitPayment || isSubmitingPayment) && selectedPaymentMethodId === 'new') || submiting || !selectedPaymentMethodId} onClick={() => handleSubscribe()}>
                { submiting ? <CircularProgress size={15} color='inherit'  /> : 'Subscribe'}
            </MDButton>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
                <Typography variant='caption' sx={{ fontSize: 15, textAlign: 'center', width: '100%', lineHeight: '1.7rem' }}>
                    By confirming your subscription, you allow Stripe to <br />charge you for future payments in accordance with their <br/>terms. You can always cancel your subscription.
                </Typography>
            </Box>

            <Dialog open={showSuccessModal} fullScreen fullWidth>
                <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    <img src={ConfettisImage} alt='Conffetis' width={'450px'} />
                    <img src={CheckedImage} alt='Checked' width={'45px'} style={{ position: 'absolute', top: '100px' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 4, justifyContent: 'center' }}>
                    <Typography variant='body2' fontSize={'22px'} fontWeight={'bold'}>
                        Payment Successful! 🎉
                    </Typography>
                    <Typography variant='body2'>
                        Thank you for your payment! Your transaction has been completed securely through Stripe.
                    </Typography>
                    <Typography variant='body2'>
                        If you have any questions or need assistance, feel free to reach out to our support team.
                    </Typography>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

export default Payments;