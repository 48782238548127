import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult } from '../endpoint';
import { IProfile } from 'models/profile';
import { IProfileContact } from '../../models/profileContact';
import { RunningProcess } from 'models/enums/runningProcess';
import { IBilling, ISelectedProfile } from './profile.types';

function ProfileService() {
  const { execute } = useFetchWithMsal();

  const API_PATH = `${API_ENDPOINT}/profile`;

  // Gets an order
  function getProfile() {
    return execute({
      endpoint: `${API_PATH}`,
      method: 'GET',
    });
  }

  function getProfilesByFilter(filterTerm?: string ): Promise<IObjectResult<ISelectedProfile>> {
    return execute({
      endpoint: `${API_PATH}/profile-list?filter=${filterTerm}`,
      method: 'GET'
    })
  }

  function getLoggedInProfile(): Promise<IProfile> {
    return execute({
      endpoint: `${API_PATH}/loggedin`,
      method: 'GET',
    });
  }

  function postProfile(profile: IProfile) {
    return execute({
      endpoint: `${API_PATH}`,
      method: 'POST',
      data: profile,
    });
  }
  
  function updateDefaultPaymentMethod(objEnv: {defaultPaymentMethodId: string, stripeCustomerId: string }) {
    return execute({
      endpoint: `${API_PATH}/update-default-payment-method`,
      method: 'PUT',
      data: objEnv,
    });
  }

  function postProfileContact(contact: IProfileContact) {
    return execute({
      endpoint: `${API_PATH}/contact`,
      method: 'POST',
      data: {
        profileContact: contact,
      },
    });
  }

  function getOrderTransactions() {
    return execute({
      endpoint: `${API_PATH}/getOrderTransactions?timestamp=${new Date().getTime()}`,
      method: 'GET',
    });
  }

  function getCreditTransactions() {
    return execute({
      endpoint: `${API_PATH}/getCreditTransactions?timestamp=${new Date().getTime()}`,
      method: 'GET',
    });
  }

  function getProfilePaymentMethods() {
    return execute({
      endpoint: `${API_PATH}/profilePaymentMethods`,
      method: 'GET',
    });
  }

  const getBillingInfo = (): Promise<IObjectResult<IBilling>> =>  {
    return execute({
      endpoint: `${API_ENDPOINT}/v2/billing`,
      method: 'GET',
    });
  }

  function deleteProfilePaymentMethod(paymentMethodId: string) {
    return execute({
      endpoint: `${API_PATH}/profilePaymentMethods/${paymentMethodId}`,
      method: 'DELETE',
    });
  }

  function addProfilePaymentMethod(paymentMethodId: string) {
    return execute({
      endpoint: `${API_PATH}/profilePaymentMethods`,
      method: 'POST',
      data: { paymentMethodId },
    });
  }

  function setDefaultPricing(deleteSubscription?: boolean) {
    return execute({
      endpoint: `${API_PATH}/set-default-pricing`,
      method: 'POST',
      data: { deleteSubscription },
    });
  }

  function gettingTaggingProcessIsRunning(): Promise<IObjectResult<boolean>> {
    return execute({ 
      endpoint: `${API_PATH}/processisrunning/${RunningProcess.Tagging}`,
      method: 'GET',
    });
  }

  function getTransactionSummary() {
    return execute({ 
      endpoint: `${API_PATH}/creditTransactions`,
      method: 'GET',
    });
  }

  function getOrderSummary() {
    return execute({ 
      endpoint: `${API_PATH}/orderTransactions`,
      method: 'GET',
    });
  }

  function getAvailableImpersonationProfile(): Promise<IObjectResult<IProfile[]>> {
    return execute({ endpoint: `${API_PATH}/impersonation`, method: 'GET' });
  }

  return {
    getProfile,
    getLoggedInProfile,
    postProfile,
    postProfileContact,
    getOrderTransactions,
    getCreditTransactions,
    getProfilePaymentMethods,
    deleteProfilePaymentMethod,
    addProfilePaymentMethod,
    setDefaultPricing,
    getBillingInfo,
    getProfilesByFilter,
    getTransactionSummary,
    getOrderSummary,
    updateDefaultPaymentMethod,
    gettingTaggingProcessIsRunning,
    getAvailableImpersonationProfile
  };
}

export default ProfileService;
