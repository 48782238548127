import React, { useState } from 'react';

import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { IDealSubscription } from 'services/checkout/checkout.types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CircularProgress, Dialog, Skeleton, Typography } from '@mui/material';

import Product from './Product';
import MDInput from 'material-ui/components/MDInput';
import Payments from './Payments';
import MDButton from 'material-ui/components/MDButton';
import CheckoutWrapper from 'newStandard/src/components/Checkout/CheckoutWrapper';
import useCheckoutDataService from 'services/checkout';

const Checkout: React.FC = () => {

    const { id } = useParams();
    
    const { enqueueSnackbar } = useSnackbar();

    const { getDataByDealId, forwardPaymentLink } = useCheckoutDataService();

    const [ sendingEmail, setSendingEmail ] = useState(false);
    const [ emailToSendPaymentLink, setEmailToSendPaymentLink ] = useState('');

    const { mutate: forwardLink } = useMutation({
        mutationFn: forwardPaymentLink,
        onSuccess: () => {
            enqueueSnackbar('All set, the payment link has been successfully sent.', { variant: 'success' } );
            setSendingEmail(false);
        },
    });
    
    const { data: CheckoutData, isLoading: isLoadingCheckoutData } = useQuery({ 
        queryKey: ['checkoutData'], 
        queryFn: () => getDataByDealId(id ?? ''),
        enabled: !!id
    });

    const getTotalPrice = (items: IDealSubscription[]) => {
        if(!items) return

        return items.reduce((total, item) => total + item.totalPrice, 0)?.toLocaleString("en-US", {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        });
    };
    
    const handleSendEmail = () => {
        setSendingEmail(true);
        
        const objEnv = {
            ...CheckoutData?.payload,
            billingManagerEmail: emailToSendPaymentLink
        }

        forwardLink(objEnv)
    }

    return (
        <Dialog open={true} fullWidth fullScreen>
            <Box sx={{ display: 'flex', minHeight: '90vh' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '2.3rem 4%'}}>
                    <Box component={'img'} src='http://localhost:3000/static/media/logo-blue.402591d01e8641f17c8a.png' width={160} />
                   
                    <Typography variant='h1' sx={{ fontSize: '22px', color: 'black', marginTop: '2rem'}}>
                        Subscriptions
                    </Typography>

                    {
                        !isLoadingCheckoutData && CheckoutData?.payload?.dealSubscriptions?.map((sub, idx: number) => (
                            <Product key={idx} data={sub} />
                        ))
                    }

                    {
                        isLoadingCheckoutData && (
                            <React.Fragment>
                                {
                                    [0,1,2].map(l => (
                                        <Box key={l} sx={{ display: 'flex' }}>
                                            <Skeleton width={'60px'} height={'100px'} />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '1rem', width: '100%' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                    <Typography component={'b'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                        <Skeleton width={'320px'} height={'45px'} />
                                                    </Typography>
                                                    <Typography component={'b'} sx={{ display: 'flex', gap: 1, alignItems: 'center', fontSize: '13px', opacity: '.7'}}>
                                                        <Skeleton width={'80px'} height={'20px'} /> / <Skeleton width={'60px'} height={'20px'} />
                                                    </Typography>
                                                </Box>
                                                <Typography variant='h5' sx={{ fontWeight: 'normal', color: 'grey' }}>
                                                    <Skeleton width={'180px'} height={'20px'} />
                                                </Typography>
                                                <Typography variant='h5' sx={{ fontWeight: 'normal', color: 'grey' }}>
                                                    <Skeleton width={'140px'} height={'20px'} />
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </React.Fragment>
                        )
                    }

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '2rem 0', margin: '2rem 0', borderTop: '1px solid rgba(0,0,0,.3)'}}>
                        <Typography variant='h1' sx={{ fontSize: '18px', color: 'black !important', marginTop: '.25rem'}}>
                            Total Charged Today
                        </Typography>
                        {
                            isLoadingCheckoutData ? (
                                <Skeleton width={'140px'} height={'60px'} />
                            ) : (
                                <Typography variant='h1' sx={{ fontSize: '19px', fontWeight: 'normal !important', color: 'black !important', marginTop: '.25rem'}}>
                                    $ {getTotalPrice(CheckoutData?.payload?.dealSubscriptions ?? [])}
                                </Typography>
                            )
                        }
                    </Box>
                </Box>

                <Box sx={{ padding: '5rem 8rem ', width: '50%', height: '100vh', boxShadow: '-5px 0px 19px 4px rgba(0, 0, 0, 0.04)'}}>
                    <Box sx={{ width: '100%', maxWidth: '550px', margin: '0 auto'}}>

                        <CheckoutWrapper isLoading={false}>
                            <Typography variant='h1' sx={{ fontSize: '22px', color: 'black', marginTop: '1.9rem', marginBottom: '2rem'}}>
                                Select Payment Method
                            </Typography>
                            <Payments data={CheckoutData?.payload} />
                        </CheckoutWrapper>

                        <Box sx={{ margin: '3rem 0 0 0', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='caption' sx={{ fontSize: '14px', paddingRight: '1rem', marginRight: '1rem', borderRight: '1px solid rgba(0,0,0,.1)' }}>Powered by <b>LettrLabs</b></Typography>
                            <Typography component={'a'} target="_blank" href='https://www.lettrlabs.com/company/terms-of-service' variant='caption' sx={{ fontSize: '14px', color: 'black !important', '&:hover': {color: '#F05A2A !important'}}}>Terms</Typography>
                            <Typography component={'a'} target="_blank" href='https://www.lettrlabs.com/company/privacy-policy'  variant='caption' sx={{ fontSize: '14px', marginLeft: '1rem', color: 'black !important', '&:hover': {color: '#F05A2A !important'}}}>Privacy</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ maxWidth: '550px', margin: '0 auto' }}>
                        <Typography variant='h1' sx={{ fontSize: '22px', color: 'black', marginTop: '3rem'}}>
                            Need someone else to pay for you ?
                        </Typography>
                        <Box sx={{ display: 'flex', margin: '1rem 0 0 0', flexDirection: 'column', alignItems: 'start', border: '1px solid rgba(0,0,0,.1)', borderRadius: 2, padding: '1rem 2rem'}}>
                            <Typography variant='body2' sx={{ fontSize: 14  }}>
                                Enter e-mail address and sent payment Link:
                            </Typography>
                            <Box sx={{ margin: '1rem 0 0 0', display: 'flex', gap: 2, width: '100%' }}>
                                <MDInput placeholder="Type E-mail address here" sx={{ width: '90%'}} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailToSendPaymentLink(e.target.value)} />
                                <MDButton disabled={!emailToSendPaymentLink || sendingEmail} onClick={handleSendEmail}>
                                    { sendingEmail ? <CircularProgress size={15} color='inherit'  /> : 'Send'}
                                </MDButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

export default Checkout;